.container:hover svg path,
.container:hover {
  background: #1a94ff;
  color: white !important;
  stroke: white;
  fill: white;
}
.container svg path {
  background: white;
  color: #2282bc !important;
  stroke: #2282bc;
  fill: #2282bc;
}
.container {
  width: 93%;
  border-radius: 16px;
  padding-top: 15px;
  cursor: pointer;
  :global {
    .ant-card {
      height: 100px !important;
    }
    .ant-card-body {
      padding: 0;
      padding-left: 27px;
    }

    .title-head {
      display: flex;
      flex-direction: row;
    }
    .title-name {
      font-weight: bold;
      padding-left: 10px !important;
      font-size: 18px;
    }

    .title-name :hover {
      color: #2282bc;
    }
    .title-number {
      width: 148px;
      height: 34px;
      font-size: 28px;
      font-weight: 700;
      // font-family: 'Inter';
    }
    .title-arrow {
      position: absolute;
      right: 35px;
    }
  }
}
