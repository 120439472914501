.spin {
  //   background-image: url('../../components/background-img/background.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  // min-height: calc(100vh - 44px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
