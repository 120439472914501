.slider {
  border: none;
  height: 100vh;
  position: fixed !important;
  overflow: auto;
  left: 0;
  top: 0;
  bottom: 0;

  .logo {
    background-image: url(../../components/background-img/sidebar.jpg);
    // background-color: #fafafa;
    display: flex;
    justify-content: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  // :global {
  //   // .ant-layout-sider-children {
  //   //   background-image: url(../../components/background-img/sidebar.jpg);
  //   //   float: left;
  //   //   background-repeat: no-repeat;
  //   //   background-position-x: 100px;
  //   //   background-position-y: 446px;
  //   //   background-size:70% 70%;
  //   // }

  //   // .ant-menu-inline,
  //   // .ant-menu-vertical,
  //   // .ant-menu-vertical-left {
  //   //   border-right: 0px;
  //   // }
  // }
}

.bold {
  font-weight: 600;
}

.sidebar {
  background-image: url(../../components/background-img/sidebar.jpg);
  // background-size: cover;
  // float: left;
  // background-repeat: no-repeat;
  // background-position-y: 10%;
  background-size: cover;
  display: flex;
  justify-content: center;
  // border:none

  :global {
    .ant-menu-item-selected a {
      color: #2282bc;
    }

    .ant-menu-item a:hover {
      color: #2282bc;
    }

    ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
      background-image: url(../../components/background-img/sidebar.jpg);
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: #a3c5de;
    }

    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
      color: #2282bc;
    }

    .ant-menu-item-selected {
      color: #2282bc;
      border-radius: 15px;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      padding-left: 14px !important;

      .ant-menu-title-content {
        font-weight: 600;
      }
    }

    .ant-menu-sub.ant-menu-inline>.ant-menu-item,
    .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
      padding-left: 28px !important;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border-right: none;
    }

    ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
      background-image: url(../../components/background-img/sidebar.jpg);

      border: none;
    }

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: #2282bc;
    }

    .ant-menu-title-content {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: none!important;
  }
  }
}