// Custom Form
.ant-row .ant-form-item {
  margin-bottom: 12px;
}

.ant-col .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-btn-primary {
  background-color: #1a94ff;
  border-color: #1a94ff;
  opacity: 0.8;
  border-radius: 4px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  // color: #fff;
  border-color: #1a94ff;
  background: #1a94ff;
  opacity: 1;
}

.ant-table-pagination {
  margin: 0 !important;
  padding: 10px;
  background-color: white;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label::after {
  content: '';
}

// .ant-form-item-label .ant-form-item-required::after {
//   content: '*' !important;
//   color: red;
//   margin-left: 4px;
// }

.ant-layout-sider-trigger {
  background-image: url(../components/background-img/sidebar.jpg);
  background-size: cover;
  color: #000;
  font-size: 26px;
}

.ant-layout-sider {
  background-image: url(../components/background-img/sidebar.jpg);

  height: calc(100vh - 48px);
  overflow-x: hidden;
}

.ant-picker-panel-container {
  .ant-picker-panel {
    .ant-picker-footer {
      ul.ant-picker-ranges {
        li.ant-picker-now {
          a.ant-picker-now-btn {
            display: none !important;
          }
        }
      }
    }
  }
}
label.ant-form-item-required::after {
  content: '*' !important;
  display: block;
  color: red !important;
}