.content {
  background-color: #fafafa;
  min-height: calc(100vh - 44px);
  overflow: auto;
  // background-image: url('../../components/background-img/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.section {
  overflow: hidden;
  // background-image: url('../../components/background-img/background.jpg');
}

.collapsed {
  padding-left: 80px;
}

.expanded {
  padding-left: 255px;
}
