.wrapInfo {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  line-height: 14px;

  .content {
    flex: 1 1 0%;
    padding: 0px 10px;

    .textProfileName {
      margin: 0;
      color: '#111111';
      font-size: 14px;
    }
  }
}

.arrowIcon {
  transition: all 0.25s ease-in-out;
  align-items: center;
  justify-content: center;
  height: 12px;
  margin-top: 2px;
  display: flex;
}

.dropDownContainer {
  background-color: #fff;
  border-radius: 15px;
  padding: 5px;
}

.dropDown {
  display: flex;
  align-items: center;
  padding: 3px;
}

.dropDownBtn {
  width: 130px;
  border: none !important;
}

.textModal {
  margin-left: 10px;
}

.active {
  transform: rotate(180deg);
}

.textGrey {
  color: #767676;
}

.bell {
  margin-top: 6px;
  margin-right: 20px;
  cursor: pointer;
}

// .notification {
//   max-height: 46vh;
//   overflow: hidden;
//   &:hover {
//     overflow-y: scroll;
//   }

.scrollBarWrap {
  width: 300px;
  position: relative;
}

.notification {
  width: 100%;
  max-height: 46vh;
  overflow-y: scroll;
}
.notification::-webkit-scrollbar {
  width: 0.4em;
}
.notification::-webkit-scrollbar,
.notification::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
.notification::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.coverBar {
  position: absolute;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  width: 0.4em;
  -webkit-transition: all 0.5s;
  opacity: 1;
}
/* MAGIC HAPPENS HERE */
.scrollBarWrap:hover .coverBar {
  opacity: 0;
  -webkit-transition: all 0.5s;
}

// .notification::-webkit-scrollbar {
//   display: none;
//   /* Safari and Chrome */
// }

// .notification {
//   // width: 240px;
//   max-height: 46vh;
//   overflow-y: scroll;
//   background-color: #f6f6f6;

//   &::-webkit-scrollbar {
//     background-color: transparent;
//     width: 0px;
//   }
// }
// .notification:hover {
//   &::-webkit-scrollbar {
//     width: 8px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.2);
//   }
// }
// @media (hover: none) {
//   .notification {
//     &::-webkit-scrollbar {
//       width: 8px;
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: rgba(0, 0, 0, 0.2);
//     }
//   }
// }

.info {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  margin: 0;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;

  &:hover {
    background: #1a94ff;
    color: white !important;
    border-radius: 15px;
  }
}

.notRead {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 15px;
}

.notRead:hover {
  background: #1a94ff;
  color: white !important;
  border-radius: 15px;
  fill: white;
  stroke: white;
}

.logoNoti {
  margin: -7px 3px 0px -5px;
  width: 20%;
  border: none !important;
}

.time {
  color: '#1a94ff !impotant';
}

.iconWarning {
  font-size: 10px;
  color: '#1a94ff';
}

:global {
  .ant-popover-inner {
    width: 320px;
  }
}
