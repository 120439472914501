.wrapHeader {
  // background-image: url('../../components/background-img/header2.jpg');
  background-color: #a3c5de;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8px 24px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  // margin-bottom: 1%;
  border: none;
}
