.tableWrapper {
  :global {
    // .ant-table-tbody .ant-table-row:nth-child(even) {
    //   background-color: rgba(255, 255, 255, 0.85);
    // }
    .ant-table {
      background: rgba(255, 255, 255, 0.85);
    }
    ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
      justify-content: center;
    }

    li.ant-pagination-item.ant-pagination-item-active {
      font-weight: 500;
      background-color: #2282bc;
      border-color: #2282bc;
      width: 31px;
      height: 32px;
      gap: 10px;
    }

    li.ant-pagination-item.ant-pagination-item-active a {
      font-size: 14px;
      color: white;
    }

    li.ant-pagination-item a {
      font-size: 14px;
      color: #172b4d;
    }

    button.ant-pagination-item-link {
      border-radius: 8px;
      background-color: #f2f3f4;
    }
    li.ant-pagination-item {
      border-radius: 8px;
      background-color: #f2f3f4;
      width: 31px;
      height: 32px;
      gap: 10px;
    }
    ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
      background: rgba(255, 255, 255, 0.85);
    }
    .ant-table-thead .ant-table-cell {
      background: rgba(255, 255, 255, 0.85);
      font-weight: 600;
      font-size: 14px;
      color: #2d3540;
    }
  }
}
